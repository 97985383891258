import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`protected-together-vaccineswork`}</p>
    <p>{`April 24th - 30th is recognized by WHO as World Immunization Week 2018. Immunization saves millions of lives and is world’s most successful and cost-effective health interventions. However, more than 19 million unvaccinated children or under-vaccinated around the world,  putting them at serious risk of these potentially fatal diseases. World Immunization Week aims to ensure every person in the world is protected from vaccine preventable diseases. 'Protected together, #VaccinesWork' is this year's theme, encouraging all sections of the society to support and understand the importance of vaccination so that we can raise the standards of health globally.`}</p>
    <p><img parentName="p" {...{
        "src": "/6e94cbdbc7cbb8b02278dab810839e79/protected-together-vaccineswork-1.gif",
        "alt": null
      }}></img></p>
    <p>{`As part of the 2018 campaign, WHO and partners aim to:`}</p>
    <ul>
      <li parentName="ul">{`Highlight the importance of immunization, and the remaining gaps in global coverage`}</li>
      <li parentName="ul">{`Underscore the value of vaccines to target donor countries and the importance of investing in immunization efforts`}</li>
      <li parentName="ul">{`Highlight the ways in which everyone – from donors to individuals – can and must drive vaccine progress.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/439ef415eaf78b30e23c62bdcfc02174/b0a15/protected-together-vaccineswork-2.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRrAAAABXRUJQVlA4IKQAAADQBACdASoUABQAPtFcpk6oJSMiKAqpABoJZQC/7YzQcLr5nKzI4ln3kMUKIRXIAAD+9hEWMcggWQRm5UuvgP/6b1+G2pM8gJugCbzosfUlPWjD2AXnVG/CzmxK43JhOnMFOsKrGOR0o3bk2ioAarrpjFZMd0dEnDxTsn80FOdZC5EqYvkNX53Vk0ArOc0Zu6OsL2i4EwWZQK4qVHgzjEN/ZaAAAA==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "protected together vaccineswork 2",
            "title": "protected together vaccineswork 2",
            "src": "/static/439ef415eaf78b30e23c62bdcfc02174/b0a15/protected-together-vaccineswork-2.webp",
            "srcSet": ["/static/439ef415eaf78b30e23c62bdcfc02174/c85cb/protected-together-vaccineswork-2.webp 300w", "/static/439ef415eaf78b30e23c62bdcfc02174/b0a15/protected-together-vaccineswork-2.webp 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4b5cfbb070ed80e6f438397d6dc0819f/b0a15/protected-together-vaccineswork-3.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRnYAAABXRUJQVlA4IGoAAACQAwCdASoUABQAPtFWpE0oJCMiMBgIAQAaCWcAAC50W8xhF/0AAP703DgofKHGe2q6815CU6H4uvsY+8zL3JU7VhKl+Q8mfspKo9IyVAuY8ikN0/gxeabEga+A0GNzj3Y6wr6z/5J+gAAA')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "protected together vaccineswork 3",
            "title": "protected together vaccineswork 3",
            "src": "/static/4b5cfbb070ed80e6f438397d6dc0819f/b0a15/protected-together-vaccineswork-3.webp",
            "srcSet": ["/static/4b5cfbb070ed80e6f438397d6dc0819f/c85cb/protected-together-vaccineswork-3.webp 300w", "/static/4b5cfbb070ed80e6f438397d6dc0819f/b0a15/protected-together-vaccineswork-3.webp 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`PCMH Restore Health has since its conception, promoted vaccinations. In keeping with WHO's goals, our team will continue to strive to ensure that the benefits of vaccination are received by as many people as possible. We encourage you to join this global movement by getting yourselves, your family and friends vaccinated and educated so that we can move towards a healthier society by disease elimination and eradication.
`}<img parentName="p" {...{
        "src": "/0cc510ad2df35a1d4fa69ae45e8644eb/protected-together-vaccineswork-4.gif",
        "alt": null
      }}></img></p>
    <p>{`Share this on social media platforms to spread awareness and to highlight the importance of protection against vaccine preventable diseases.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      